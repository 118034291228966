import { useState, useEffect } from "react"

const ReactIndexHTML = () => {
    const [styleCode, setStyleCode] = useState('')
    const formatColor = { tag: 'blue', quote: 'red', string: 'brown', attr: 'aqua' }

    const code =
    `&lt;!doctype html&gt;
    &lt;html&gt;
        &lt;head&gt;
            &lt;meta charset=&quot;utf-8&quot; /&gt;
            &lt;title&gt;Welcome&lt;/title&gt;
        &lt;/head&gt;
        &lt;body&gt;
            &lt;div id=&quot;root&quot;&gt;&lt;/div&gt;
            &lt;script type=&quot;module&quot; src=&quot;src/main.jsx&quot;&gt;&lt;/script&gt;
        &lt;/body&gt;
    &lt;/html&gt;`

    useEffect(() => {

        const formatContent = String(code).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"')

        setStyleCode(code
            .replace(/(&lt;\w(.*?)\s)/g, (match) => { return `<span style="color:aqua">${match}</span>` }) //tags
            .replace(/&quot;(.*?)&quot;/g, (match) => { return `<span style="color:brown">${match}</span>` }) //attr value
            .replace(/&quot;/g, '<span style="color:red">"</span>')
            .replace(/(&lt;\/\w(.*?)&gt;)|(\/&gt;)/g, (match) => { return `<span style="color:aqua">${match}</span>` }) //tags
        )
    }, [])


    return (
        <>
            <h1>index.html</h1>
            <pre className="p-3 border text-bg-dark">
                <code dangerouslySetInnerHTML={{ __html: styleCode }}>
                    
                </code>
            </pre>
        </>
    )
}
export default ReactIndexHTML