const ReactLandingJsx = () => {
    const code = `
import { Outlet } from 'react-router-dom'
import Nav from &quot;./nav&quot;
import Footer from &quot;./footer&quot;

const Landing = ({ children }) =&gt; {
  return (
	&lt;&gt;
	  &lt;Nav /&gt;
	    &lt;div&gt;
		  &lt;Outlet /&gt;
	    &lt;/div&gt;
	  &lt;Footer /&gt;
	&lt;/&gt;
    )
}

export default Landing

    `
    return (
        <>
            <h1>landing.jsx</h1>
            <pre className="p-3 border text-bg-dark">
                <code dangerouslySetInnerHTML={{ __html: code}}></code>
            </pre>
        </>
    )
}
export default ReactLandingJsx