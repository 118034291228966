const ViteConfigJS = () => {
    const code = `
import { defineConfig, splitVendorChunkPlugin } from 'vite'
import react from '@vitejs/plugin-react'

export default defineConfig({
  plugins: [react(), splitVendorChunkPlugin()],
  build: {
    minify: true,
    rollupOptions: {
      output: {
        manualChunks: (id) =&gt; {

        },
        entryFileNames: 'app-[hash].js',
        chunkFileNames:'app-runtime-[hash].js',
        assetFileNames:'app-[hash].[ext]'
      },
    },
  }
})
    `
    return (
        <>
            <h1>vite.config.js</h1>
            <pre className="px-2 border text-bg-dark">
                <code dangerouslySetInnerHTML={{ __html: code }}>
                </code>
            </pre>
        </>
    )
}
export default ViteConfigJS