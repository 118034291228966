const ReactMainJsx = () => {
    const code = `
    import {StrictMode} from 'react'
    import {createRoot} from 'react-dom/client'
    import {BrowserRouter} from 'react-router-dom'
    import './index.css'
    import App from './App.jsx'

    createRoot(document.getElementById('root')).render(
    &lt;StrictMode&gt;
        &lt;BrowserRouter&gt;
            &lt;App /&gt;
        &lt;/BrowserRouter&gt;
    &lt;/StrictMode&gt;,
    )
    `
    return (
        <>
            <h1>main.jsx</h1>
            <pre className="p-3 border text-bg-dark">
                <code dangerouslySetInnerHTML={{ __html: code }}></code>
            </pre>
        </>
    )
}
export default ReactMainJsx