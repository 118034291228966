
const BoilerPlateHome = () => {
  
  return (
    <>
    <h1>Scaffold your code. Save time</h1>
      
    </>
  )
}
export default BoilerPlateHome