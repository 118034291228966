const ReactPackageJson = () => {
    const code = `
{
  &quot;name&quot;: &quot;myapp&quot;,
  &quot;private&quot;: true,
  &quot;version&quot;: &quot;0.0.0&quot;,
  &quot;type&quot;: &quot;module&quot;,
  &quot;scripts&quot;: {
    &quot;dev&quot;: &quot;vite&quot;,
    &quot;build&quot;: &quot;vite build&quot;,
    &quot;lint&quot;: &quot;eslint .&quot;,
    &quot;preview&quot;: &quot;vite preview&quot;
  },
  &quot;dependencies&quot;: {
    &quot;react&quot;: &quot;^19.0.0&quot;,
    &quot;react-dom&quot;: &quot;^19.0.0&quot;,
    &quot;react-router-dom&quot;:&quot;^7.1.1&quot;
  },
  &quot;devDependencies&quot;: {
    &quot;@eslint/js&quot;: &quot;^9.17.0&quot;,
    &quot;@types/react&quot;: &quot;^19.0.2&quot;,
    &quot;@types/react-dom&quot;: &quot;^19.0.2&quot;,
    &quot;@vitejs/plugin-react&quot;: &quot;^4.3.4&quot;,
    &quot;eslint&quot;: &quot;^9.17.0&quot;,
    &quot;eslint-plugin-react&quot;: &quot;^7.37.3&quot;,
    &quot;eslint-plugin-react-hooks&quot;: &quot;^5.1.0&quot;,
    &quot;eslint-plugin-react-refresh&quot;: &quot;^0.4.16&quot;,
    &quot;globals&quot;: &quot;^15.14.0&quot;,
    &quot;vite&quot;: &quot;^6.0.6&quot;
  }
}
    `
    return (
        <>
            <h1>package.json</h1>
            <pre className="p-2 border text-bg-dark">
                <code dangerouslySetInnerHTML={{__html:code} }></code>
            </pre>
        </>
    )
}
export default ReactPackageJson