import { Route, Routes } from 'react-router-dom'
import Layout from './app/components/shared/layout'
import Container from './app/components/shared/container'
import Home from './app/components/home'
import Landingpages from './app/pages/landingpages'
import BoilerplatesHome from './app/pages/boilerplates/boilerplatehome'
import IndexHtml from './app/pages/boilerplates/indexhtml'
import CodeFormatter from './app/pages/codeformatter'
import PWAIndexHtml from './app/pages/boilerplates/pwaindexhtml'
import ExpressServer from './app/pages/boilerplates/expressserver'
import Colors from './app/pages/colors'
import GradientColors from './app/pages/gradientcolors'
import ColorPicker from './app/pages/colorpicker'
import Canvas from './app/pages/canvas'
import Editor from './app/pages/Editor'
import ReactApp from './app/pages/boilerplates/react/react'
import ReactIndexHTML from './app/pages/boilerplates/react/reactindexhtml'
import ReactMainJsx from './app/pages/boilerplates/react/mainjsx'
import ReactAppJsx from './app/pages/boilerplates/react/appjsx'
import ReactPackageJson from './app/pages/boilerplates/react/packagejson'
import ViteConfigJS from './app/pages/boilerplates/react/viteconfig'
import BoilerPlatesContainer from './app/components/shared/boilerplates'
import ReactLandingJsx from './app/pages/boilerplates/react/landingjsx'
import ReactLayoutJsx from './app/pages/boilerplates/react/layoutjsx'
import HtmlEditor from './app/pages/HtmlEditor'

const App = () => {
    return (
        <Routes>
            <Route element={<Container />}>
                <Route path="*" component={<Home />} status={404} />
                <Route path="/landing-pages" element={<Landingpages />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/code-formatter" element={<CodeFormatter />} />
                <Route path="/colors" element={<Colors />} />
                <Route path="/gradient-colors" element={<GradientColors />} />
                <Route path="/color-picker" element={<ColorPicker />} />
                <Route path="/canvas" element={<Canvas />} />
                <Route path="/html-editor" element={<HtmlEditor />} />
            </Route>

            <Route element={<BoilerPlatesContainer />}>
                <Route path="/boilerplates" element={<BoilerplatesHome />} />
                <Route path="/boilerplates/indexhtml" element={<IndexHtml />} />
                <Route path="/boilerplates/pwaindexhtml" element={<PWAIndexHtml />} />
                <Route path="/boilerplates/pwaindexhtml" element={<PWAIndexHtml />} />
                <Route path="/boilerplates/express-server" element={<ExpressServer />} />
                <Route path="/boilerplates/react" element={<ReactApp />} />
                <Route path="/boilerplates/react-landing" element={<ReactLandingJsx />} />
                <Route path="/boilerplates/react-layout" element={<ReactLayoutJsx />} />
                <Route path="/boilerplates/react-package-json" element={<ReactPackageJson />} />
                <Route path="/boilerplates/react-index-html" element={<ReactIndexHTML />} />
                <Route path="/boilerplates/react-main-jsx" element={<ReactMainJsx />} />
                <Route path="/boilerplates/react-app-jsx" element={<ReactAppJsx />} />
                <Route path="/boilerplates/react-vite-config" element={<ViteConfigJS />} />
                
            </Route>
            <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
            </Route>
        </Routes>
    )
}

export default App
