const ReactAppJsx = () => {
    const code = `
import { Route, Routes } from 'react-router-dom'
import './App.css'
import Layout from './components/shared/layout'
import Landing from './components/shared/landing'
import Home from './components/home'
import About from './components/about'
import Contact from './components/shared/contact'
import Privacy from './components/privacy'
import Terms from './components/terms'

const App=()=&gt; {
  return (
    &lt;Routes&gt;
      &lt;Route element={&lt;Landing /&gt;}&gt;
        &lt;Route path=&quot;/&quot; element={&lt;Home /&gt;} /&gt;
        &lt;Route path=&quot;*&quot; component={&lt;Home /&gt;} status={404} /&gt;
      &lt;/Route&gt;
      &lt;Route element={&lt;Layout /&gt;}&gt;
        &lt;Route path=&quot;/about&quot; element={&lt;About /&gt;} /&gt;
        &lt;Route path=&quot;/contact&quot; element={&lt;Contact /&gt;} /&gt;
        &lt;Route path=&quot;/privacy&quot; element={&lt;Privacy /&gt;} /&gt;
        &lt;Route path=&quot;/terms&quot; element={&lt;Terms /&gt;} /&gt;
      &lt;/Route&gt;
    &lt;/Routes&gt;
  )
}

export default App

    `
    return (
        <>
            <h1>app.jsx</h1>
            <pre className="p-3 border text-bg-dark">
                <code dangerouslySetInnerHTML={{ __html: code}}></code>
            </pre>
        </>
    )
}
export default ReactAppJsx