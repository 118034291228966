const ReactLayoutJsx = () => {
    const code = `
import { Outlet } from 'react-router-dom'
import Nav from &quot;./nav&quot;
import Footer from &quot;./footer&quot;

const Layout = ({ children }) =&gt; {
  return (
    &lt;&gt;
      &lt;Nav /&gt;
      &lt;main className=&quot;container flex-grow-1 mb-5&quot;&gt;
        &lt;div className=&quot;my-5&quot;&gt;
          &lt;Outlet /&gt;
        &lt;/div&gt;
      &lt;/main&gt;
      &lt;Footer /&gt;
    &lt;/&gt;
  )
}

export default Layout

    `
    return (
        <>
            <h1>landing.jsx</h1>
            <pre className="p-3 border text-bg-dark">
                <code dangerouslySetInnerHTML={{ __html: code}}></code>
            </pre>
        </>
    )
}
export default ReactLayoutJsx