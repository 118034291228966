import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="py-3 py-lg-5 px-lg-5">
            <div className="container-fluid text-center">
                <Link to={'code-formatter'} className="me-3">Code Formatter</Link>
                <Link to={'html-editor'}>HTML Editor</Link>
                <hr />
                &copy; 2024 CodePx
            </div>
        </footer>
    )
}
export default Footer
