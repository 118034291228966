import { useState, useEffect, useRef } from 'react';
import { Controlled as ControlledEditor } from "react-codemirror2";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

const Editor = () => {
    const [html, setHtml] = useState('<h1>Hello, World!</h1>');
    const [css, setCss] = useState('body { font-family: Arial; }');
    const [js, setJs] = useState('');
    const iframeRef = useRef(null);

    const handleHtmlChange = (editor, data, value) => {
        setHtml(value);
    };

    const handleCssChange = (editor, data, value) => {
        setCss(value);
    };

    const handleJsChange = (editor, data, value) => {
        setJs(value);
    };

    const createDocument = () => {
        return `
          <!DOCTYPE html>
          <html>
            <head>
              <style>${css}</style>
            </head>
            <body>
              ${html}
              <script>${js}</script>
            </body>
          </html>
        `;
    };

    useEffect(() => {
        const iframe = iframeRef.current;
        const document = iframe.contentDocument || iframe.contentWindow.document;
        document.open();
        document.write(createDocument());
        document.close();
    }, [html, css, js]);

    return (
        <>
            <div className="editor-container">
                <div className="editor-pane flex-grow-1">
                    <h5>HTML</h5>
                    <ControlledEditor
                        value={html}
                        onBeforeChange={handleHtmlChange}
                        options={{
                            mode: 'xml',
                            theme: 'material',
                            lineNumbers: true
                        }}
                    />
                </div>
                <div className="editor-pane">
                    <h5>CSS</h5>
                    <ControlledEditor
                        value={css}
                        onBeforeChange={handleCssChange}
                        options={{
                            mode: 'css',
                            theme: 'material',
                            lineNumbers: true
                        }}
                    />
                </div>
                <div className="editor-pane">
                    <h5>JavaScript</h5>
                    <ControlledEditor
                        value={js}
                        onBeforeChange={handleJsChange}
                        options={{
                            mode: 'javascript',
                            theme: 'material',
                            lineNumbers: true
                        }}
                    />
                </div>
            </div>
            <div className="preview-pane">
                <iframe id="preview" title="preview" ref={iframeRef} />
            </div>
        </>
    );
};

export default Editor;
